import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import VueRouter from "vue-router"
//import lhui from './components/index'
//import 'lianghang-ui/dist/lianghang-ui.css'
import lhUI from 'lianghang-ui'
Vue.use(lhUI);
//import {LhButton} from "lianghang-ui";
//Vue.component(LhButton.name, LhButton);
Vue.use(VueRouter);
Vue.config.productionTip = false

router.beforeEach((to,from,next)=>{
  // console.log(to);
  if(to.meta.title){
    document.title=to.meta.title;
  }
  next();
})

const vue=new Vue({
  render: h => h(App),
  router
});
vue.$mount('#app');

export default vue;
